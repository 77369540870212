import AuthPage, { authRouter } from './auth'
import LandingPage from './landing'
import ProjectsPage, { projectsRouter } from './projects'

const appRouter = [{
  path: 'auth',
  element: <AuthPage />,
  children: authRouter
}, {
  path: 'projects',
  element: <ProjectsPage />,
  children: projectsRouter
}, {
  path: '',
  element: <LandingPage />
}]

export default appRouter
