import * as React from 'react'
import Box from '@mui/joy/Box'
import Divider from '@mui/joy/Divider'
import GlobalStyles from '@mui/joy/GlobalStyles'
import IconButton from '@mui/joy/IconButton'
import Input from '@mui/joy/Input'
import Link from '@mui/joy/Link'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import Sheet from '@mui/joy/Sheet'
import Typography from '@mui/joy/Typography'
import {
  LifeBuoy,Power, Search, Settings
} from 'lucide-react'

import Logo from 'components/logo'
import ModeToggle from 'components/mode-toggle'
import useMe from 'modules/state/me.hook'

import { closeProjectSidebar } from '../project.utils'

import ProjectSidebarItems from './project-sidebar-items'
import ProjectSidebarPlan from './project-sidebar-plan'

interface ProjectSidebarProps {
  selectedProject?: string
}

export default function ProjectSidebar({ selectedProject }: ProjectSidebarProps) {

  const me = useMe()

  return (
    <Sheet
      className={'Sidebar'}
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none'
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider'
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px'
            }
          }
        })}
      />
      <Box
        className={'Sidebar-overlay'}
        onClick={() => closeProjectSidebar()}
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)'
          }
        }}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
        <Link href={''}>
          <Logo />
        </Link>

        <ModeToggle />
      </Box>
      <Input
        placeholder={'Search'}
        size={'sm'}
        startDecorator={<Search />} />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5
          }
        }}
      >
        <ProjectSidebarItems selectedProject={selectedProject} />

        <List
          size={'sm'}
          sx={{
            mt: 'auto',
            flexGrow: 0,
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
            '--List-gap': '8px',
            mb: 2
          }}
        >
          <ListItem>
            <ListItemButton
              component={Link}
              href={'support'}
            >
              <LifeBuoy />
              Support
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              component={Link}
              href={'settings'}>
              <Settings />
              Settings
            </ListItemButton>
          </ListItem>
        </List>
        <ProjectSidebarPlan selectedProject={selectedProject} />
      </Box>
      <Divider />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography level={'title-sm'}>{me.name}</Typography>
          <Typography level={'body-xs'}>{me.email}</Typography>
        </Box>
        <IconButton
          color={'neutral'}
          onClick={me.logout}
          size={'sm'}
          variant={'plain'}
        >
          <Power />
        </IconButton>
      </Box>
    </Sheet>
  )
}
