import ReCAPTCHA from 'react-google-recaptcha'
import Button from '@mui/joy/Button'

import FormField from 'components/form/form-field'
import FormCheckbox from 'components/form/form-field/form-checkbox.components'

import { useRequestAccount } from './request-account.hook'

export default function RequestAccount() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading,
    setCaptcha
  } = useRequestAccount()

  return (
    <form
      onSubmit={handleSubmit}
      noValidate>
      <FormField
        field={errors.email}
        placeholder={'Email'}
        type={'email'}
        {...register('email', { required: true })}
      />
      {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (<ReCAPTCHA
        onChange={setCaptcha}
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      />)}
      <FormCheckbox
        field={errors.terms_accepted}
        label={'I agree to Terms and Privacy'}
        {...register('terms_accepted', { required: true })}
      />
      <Button
        loading={loading}
        type={'submit'}>
        Request Account
      </Button>
    </form>
  )
}
