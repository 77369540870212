import React from 'react'

export default function ProjectsSettings() {

  return (
    <>
      Projects settings
    </>
  )
}
