import ReCAPTCHA from 'react-google-recaptcha'
import { Checkbox } from '@mui/joy'
import Button from '@mui/joy/Button'

import FormField from 'components/form/form-field'
import FormCheckbox from 'components/form/form-field/form-checkbox.components'
import FormPasswordField from 'components/form/form-field/form-password-field.components'

import { useRegister } from './register.hook'

export default function RegisterPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading,
    setCaptcha,
    registerDone
  } = useRegister()

  if (registerDone) {
    return (
      <>
        Register done see email
      </>
    )
  }

  return (
    <form
      onSubmit={handleSubmit}
      noValidate>
      <FormField
        field={errors.email}
        placeholder={'Email'}
        type={'email'}
        {...register('email', { required: true })}
      />
      <FormPasswordField
        field={errors.password}
        placeholder={'Password'}
        {...register('password', { required: true })}
      />
      <FormPasswordField
        field={errors.confirm_password}
        placeholder={'Confirm Password'}
        noMeter
        {...register('confirm_password', { required: true })}
      />
      {/*{process.env.REACT_APP_RECAPTCHA_SITE_KEY && (<ReCAPTCHA*/}
      {/*  onChange={setCaptcha}*/}
      {/*  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}*/}
      {/*/>)}*/}
      <FormCheckbox
        field={errors.terms_accepted}
        label={'I agree to Terms and Privacy'}
        {...register('terms_accepted', { required: true })}
      />
      <Button
        loading={loading}
        type={'submit'}>
        Register
      </Button>
    </form>
  )
}
