import React from 'react'
import IconButton from '@mui/joy/IconButton'
import { useColorScheme } from '@mui/joy/styles'
import { SunMoon } from 'lucide-react'

export default function ModeToggle() {
  const { mode, setMode } = useColorScheme()
  const [mounted, setMounted] = React.useState(false)

  // necessary for server-side rendering
  // because mode is undefined on the server
  React.useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return null
  }

  return (
    <IconButton
      variant={'plain'}
      onClick={() => {
        setMode(mode === 'light' ? 'dark' : 'light')
      }}
    >
      <SunMoon />
    </IconButton>
  )
}
