import React, { useEffect } from 'react'

import isEmpty from '../../utils/isEmpty'
import cookie from '../cookie'
import Rest, { ListResponse, RestContext } from '../rest'

import { Invalidate } from './state.types'

type Me = {
  id: string
  name: string
  email: string
}

type Auth = Me & Invalidate<Me> & {
  logout: () => void
  isLoggedIn: boolean
}

export default function useMe(onMount = true): Auth {

  const { me, update } = React.useContext(RestContext)

  const fetchMe = React.useCallback(async (): Promise<Me | null> => {
    const token = cookie.get('token')
    console.log('token', token)
    if (token) {
      if (!isEmpty(me)) {
        return me as Me
      }
      try {
        const response: ListResponse<Me> = await Rest.users.all()
        const loggedInUser = response.results[0]
        update('me', loggedInUser)
        return loggedInUser
      } catch {
        // Something is not correct log out user
      }
    }

    logout()

    return null
  }, [])

  useEffect(() => {
    if (onMount) {
      fetchMe()
    }

  }, [onMount])

  function invalidate() {
    return fetchMe()
  }

  function logout() {
    cookie.remove('token')
    update('me', null)
  }

  return {
    ...(me as Me),
    isLoggedIn: !isEmpty(me),
    invalidate,
    logout
  }
}
