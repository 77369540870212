import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import i18n from 'modules/i18n'
import useMe from 'modules/state/me.hook'

export default function AppPage() {
  const location = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(true)
  const me = useMe(false)

  React.useEffect(() => {
    Promise.all([
      i18n,
      me.invalidate()
    ]).finally(() => {
      setLoading(false)
    })
  }, [])

  React.useEffect(() => {
    if (!loading) {
      if (me.isLoggedIn && !location.pathname.startsWith('/projects')) {
        navigate('/projects', { replace: true })
      }
      if (!me.isLoggedIn && location.pathname.startsWith('/projects')) {
        navigate('/', { replace: true })
      }
    }
  }, [location.pathname, me.isLoggedIn, loading])

  if (loading) {
    // TODO: maybe better loader ?
    return null
  }
  console.log({ me })

  return (
    <>
      <Outlet />
    </>
  )
}
