import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useNavigation } from 'react-router-dom'
import Box from '@mui/joy/Box'

import ProjectHeader from 'components/project/project-header/project-header.component'
import ProjectSidebar from 'components/project/project-sidebar/project-sidebar.component'
import useRestStore from 'modules/rest/rest.hooks'

export default function ProjectsPage() {
  const location = useLocation()
  const { pathname } = location
  const navigate = useNavigate()
  const [selectedProject] = useRestStore<string>('selectedProject')

  useEffect(() => {
    if (!selectedProject) {
      if (!['/projects', '/projects/support','/projects/settings'].includes(pathname) && !pathname.startsWith('/projects-create')) {
        console.log('not selectedProject')
        navigate('/projects', { replace: true })
      }

    } else {
      if (pathname === '/projects' || pathname.startsWith('/projects-create')) {
        navigate(`/projects/${selectedProject}`, { replace: true })
      }
    }

  }, [selectedProject, pathname])

  return (
    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
      <ProjectHeader />
      <ProjectSidebar selectedProject={selectedProject} />
      <Box
        className={'MainContent'}
        component={'main'}
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: 'calc(12px + var(--Header-height))',
            md: 3
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 1
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}
