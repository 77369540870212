import Methods from './methods'

const Rest = {
  auth: new Methods( '/api/auth/'),

  register: new Methods( '/api/auth/register/'),
  activation: new Methods('/api/auth/activation'),
  requestAccount:  new Methods('/api/auth/request-account'),

  resetPassword: new Methods('/api/auth/reset_password'),
  resetPasswordSet: new Methods('/api/auth/reset_password/set'),

  users: new Methods('/api/users/'),
  passwordChange: new Methods('/api/users/:id/password/'),

  projects: new Methods('/api/projects/'),

  errors: new Methods('/api/errors/')

}

export default Rest
