import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Button from '@mui/joy/Button'
import Container from '@mui/joy/Container'
import Stack from '@mui/joy/Stack'

import Logo from 'components/logo'
import ModeToggle from 'components/mode-toggle'

export default function Header() {
  const { t } = useTranslation()

  return (
    <Container maxWidth={'lg'}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        py={1}>
        <Link to={'/'}>
          <Logo />
        </Link>

        <Stack
          alignItems={'center'}
          direction={'row'}
          gap={1}>

          <ModeToggle />

          <Button
            component={Link}
            to={'/auth'}
            variant={'plain'}>
            {t('Login')}
          </Button>
          <Button
            component={Link}
            to={'/auth/register'}
            variant={'outlined'}>
            {t('Register')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}
