import React from 'react'

import ProjectsDetailBlocks from './projects-detail-blocks'
import ProjectsDetailMessages from './projects-detail-messages'
import ProjectsDetailOverview from './projects-detail-overview'
import ProjectsDetailPages from './projects-detail-pages'
import ProjectsDetailPlan from './projects-detail-plan'
import ProjectsDetailTags from './projects-detail-tags'
import ProjectsDetailTasks from './projects-detail-tasks'
import ProjectsDetailTeam from './projects-detail-team'

const projectsDetailRouter = [ {
  path: 'pages',
  element: <ProjectsDetailPages />
}, {
  path: 'blocks',
  element: <ProjectsDetailBlocks />
}, {
  path: 'tasks',
  element: <ProjectsDetailTasks />
}, {
  path: 'tags',
  element: <ProjectsDetailTags />
}, {
  path: 'messages',
  element: <ProjectsDetailMessages />
}, {
  path: 'team',
  element: <ProjectsDetailTeam />
}, {
  path: 'plan',
  element: <ProjectsDetailPlan />
}, {
  path: '',
  element: <ProjectsDetailOverview />
}]

export default projectsDetailRouter
