import React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import cookie from 'modules/cookie'
import Rest from 'modules/rest'
import useMe from 'modules/state/me.hook'

type LoginInputs = {
  email: string
  password: string
}

type LoginResponse = {
  token: string
  user: string
}

export function useLogin() {
  const [loading, setLoading] = React.useState(false)
  const { invalidate } = useMe(false)
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<LoginInputs>()

  console.log('watch', watch)
  function login(data: LoginInputs) {
    setLoading(true)
    Rest.auth.post(data).then(async (response: LoginResponse) => {
      cookie.set('token', response.token, {
        expires: parseInt(process.env.REACT_APP_TOKEN_EXPIRES || '')
      })
      await invalidate()
      navigate('/')
    }).catch((error) => {
      // TODO: error message
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  return {
    register,
    handleSubmit: handleSubmit(login),
    formState: { errors },
    loading
  }
}
