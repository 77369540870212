import React from 'react'

export default function ProjectsDetailPlan() {

  return (
    <>
      Plan
    </>
  )
}
