import isArray from './isArray'

export default function get(object: Record<string, unknown>, path: string | string[], defaultValue: unknown = undefined): unknown {
  let paths: string[]
  if (isArray(path)) {
    paths = path as string[]
  } else {
    paths = String(path)
      .replace(/\[/g, '.')
      .replace(/]/g, '')
      .split('.')
  }
  let retVal = undefined
  let currObj = object
  try {
    paths.forEach(function(level) {
      retVal = currObj[level]
      currObj = currObj[level] as Record<string, unknown>
    })
  } catch {
    retVal = undefined
  }

  if (retVal === undefined) {
    return defaultValue
  }

  return retVal
}
