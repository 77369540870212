import React from 'react'

import get from 'utils/get'

import { RestContext } from './rest.context'

export default function useRestStore<T>(key: string): [T, (data: T | null) => void] {

  const store = React.useContext(RestContext)
  const { update, ...rest } = store

  const setStore = React.useCallback((data: T | null) => {
    update(key, data)
  }, [])

  const value = get(rest, key) as T
  // TODO: improve rendering
  return [
    value,
    setStore
  ]

}
