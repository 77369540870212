import React from 'react'

import ProjectsDetail, { projectsDetailRouter } from './projects-detail'
import ProjectsManage from './projects-manage'
import ProjectsSettings from './projects-settings'
import ProjectsSupport from './projects-support'

const projectsRouter = [{
  path: ':id',
  element: <ProjectsDetail />,
  children: projectsDetailRouter
}, {
  path: 'support',
  element: <ProjectsSupport />
}, {
  path: 'settings',
  element: <ProjectsSettings />
}, {
  path: '',
  element: <ProjectsManage />
}]

export default projectsRouter
