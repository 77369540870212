import React from 'react'

export default function ProjectsDetailOverview() {

  return (
    <>
      Overview
    </>
  )
}
