import Login from './login'
import Register from './register'
import RequestAccount from './request-account'

const authRouter = [{
  path: 'request-account',
  element: <RequestAccount />
},{
  path: 'register',
  element: <Register />
}, {
  path: '',
  element: <Login />
}]

export default authRouter
