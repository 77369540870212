import * as React from 'react'
import Link from '@mui/joy/Link'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Typography from '@mui/joy/Typography'
import {
  Layers, LayoutDashboard, LayoutTemplate, ListTodo, MessagesSquare,
  Tag, Users
} from 'lucide-react'

interface ProjectSidebarItemsProps {
  selectedProject?: string
}

export default function ProjectSidebarItems({ selectedProject }: ProjectSidebarItemsProps) {

  if (!selectedProject) {
    return null
  }

  return (
    <List
      size={'sm'}
      sx={{
        gap: 1,
        '--List-nestedInsetStart': '30px',
        '--ListItem-radius': (theme) => theme.vars.radius.sm
      }}
    >
      <ListItem>
        <ListItemButton
          component={Link}
          href={selectedProject}
        >
          <LayoutDashboard />
          <ListItemContent>
            <Typography>
              Overview
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem>
        <ListItemButton
          component={Link}
          href={`${selectedProject}/pages`}>
          <Layers />
          <ListItemContent>
            <Typography>
              Pages
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem>
        <ListItemButton
          component={Link}
          href={`${selectedProject}/blocks`}
          selected>
          <LayoutTemplate />
          <ListItemContent>
            <Typography>
              Blocks
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem>
        <ListItemButton
          component={Link}
          href={`${selectedProject}/tasks`}>
          <ListTodo />
          <ListItemContent>
            <Typography>
              Tasks
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem>
        <ListItemButton
          component={Link}
          href={`${selectedProject}/tags`}>
          <Tag />
          <ListItemContent>
            <Typography>
              Tags
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem>
        <ListItemButton
          component={Link}
          href={`${selectedProject}/messages`}
        >
          <MessagesSquare />
          <ListItemContent>
            <Typography>
              Messages
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>

      <ListItem>
        <ListItemButton
          component={Link}
          href={`${selectedProject}/team`}
        >
          <Users />
          <ListItemContent>
            <Typography>
              Team
            </Typography>
          </ListItemContent>
        </ListItemButton>
      </ListItem>
    </List>
  )
}
