import React from 'react'
import Box from '@mui/joy/Box'
import Container from '@mui/joy/Container'

import Footer from 'components/footer'
import Header from 'components/header'

export default function LandingPage() {

  return (
    <>
      <Header/>
      <Container maxWidth={'lg'}>
        Landing page1123
      </Container>

      <Box flexGrow={1} />
      <Footer />
    </>
  )
}
