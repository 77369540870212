import React from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
  // useFetcher,
  // useHref
} from 'react-router-dom'
import { extendTheme } from '@mui/joy/styles'

export const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, 'to'> & {
  href: RouterLinkProps['to']
}>((props, ref) => {
  // const routeFetcher = useFetcher()
  //
  // const isExternal = typeof props.href === 'string' && props.href.startsWith('http')
  // const to = useHref(props.href)
  //
  // const onHover = React.useCallback(() => {
  //   if (!isExternal && to && to !== '/' && !to.startsWith('/#')) {
  //     routeFetcher.load(to)
  //   }
  // }, [routeFetcher, to, isExternal])

  return (
    <RouterLink
      ref={ref}
      // onMouseEnter={onHover}
      // to={isExternal ? props.href : to}
      to={props.href}
      {...props} />
  )
})

export default extendTheme({

  components: {
    JoyLink: {
      defaultProps: {
        component: LinkBehavior,
        underline: 'none'
      }
    }
  }

})
