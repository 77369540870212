import React from 'react'

export default function ProjectsDetailTags() {

  return (
    <>
      Tags
    </>
  )
}
