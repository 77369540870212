import React from 'react'

export default function ProjectsDetailTasks() {

  return (
    <>
      Tasks
    </>
  )
}
