import Button from '@mui/joy/Button'

import FormField from 'components/form/form-field'
import FormPasswordField from 'components/form/form-field/form-password-field.components'

import { useLogin } from './login.hook'

export default function LoginPage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading
  } = useLogin()

  return (
    <form onSubmit={handleSubmit}>
      <FormField
        color={'primary'}
        field={errors.email}
        placeholder={'Email'}
        type={'email'}
        {...register('email', { required: true })}
      />
      <FormPasswordField
        field={errors.password}
        placeholder={'Password'}
        noMeter
        {...register('password', { required: true })}
      />
      <Button
        loading={loading}
        type={'submit'}>
        Login
      </Button>
    </form>
  )
}
