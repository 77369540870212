import React from 'react'
import Button from '@mui/joy/Button'

import useRestStore from 'modules/rest/rest.hooks'

export default function ProjectsManage() {
  // TODO: Display list of project with option to select or to
  //  create new

  // TODO: When selected persist it for next login
  //  When this page is reached and persisted project id exists just navigate to that projects overview page

  const [selectedProject, setSelectedProject] = useRestStore<string>('selectedProject')

  return (
    <>
      Projects Manage
      <Button
        onClick={() => setSelectedProject('1')}>
        test select
      </Button>
    </>
  )
}
