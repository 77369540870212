import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/joy'
import Button from '@mui/joy/Button'
import Container from '@mui/joy/Container'
import Link from '@mui/joy/Link'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'

export default function Footer() {
  const { t, i18n } = useTranslation()
  const appName = process.env.REACT_APP_NAME

  const languages: Record<string, string> = {
    en: 'English',
    nl: 'Nederlands',
    de: 'Deutsch',
    sr: 'Srpski'
  }
  console.log('i18n.language', i18n.language)
  const date = new Date()
  return (
    <Container maxWidth={'lg'}>
      <Divider />
      <Stack
        alignItems={'center'}
        gap={1}
        py={2}>
        <Stack
          direction={'row'}
          gap={1}>
          {Object.keys(languages).map((key: string) => (
            <Button
              key={`i18n-${key}`}
              disabled={key === i18n.language}
              onClick={() => i18n.changeLanguage(key)}
              size={'sm'}
              variant={'plain'}
            >
              {languages[key]}
            </Button>
          ))}
        </Stack>
        <Stack
          direction={'row'}
          gap={1}>
          <Link
            fontSize={'small'}
            href={'/pages/about'}>
            {t('About')}
          </Link>
          <Link
            fontSize={'small'}
            href={'/pages/help'}>
            {t('Help')}
          </Link>
          <Link
            fontSize={'small'}
            href={'/pages/privacy'}>
            {t('Privacy')}
          </Link>
          <Link
            fontSize={'small'}
            href={'/pages/terms'}>
            {t('Terms')}
          </Link>
          <Link
            fontSize={'small'}
            href={'/pages/rules'}>
            {t('Rules')}
          </Link>
        </Stack>
        <Stack>
          <Typography level={'body-sm'}>
            {t('Copyright © {{0}} {{1}}.', { 0: appName , 1: date.getFullYear() })}
          </Typography>
        </Stack>
      </Stack>
    </Container>
  )
}
