import React from 'react'

export default function ProjectsDetailMessages() {

  return (
    <>
      Messages
    </>
  )
}
