import React from 'react'

export default function ProjectsDetailTeam() {

  return (
    <>
      Team
    </>
  )
}
