import React from 'react'

type RestContextType = {
  me?: null | {}
  activeUserId?: null | string
  update: (key: string, data: null | undefined | unknown) => void
}

export const RestContext = React.createContext<RestContextType>({
  update: () => {}
})
